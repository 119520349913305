"use client";

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from 'swiper/modules';
import Link from 'next/link';
import styles from "../styles/sliders.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Image from 'next/image';


export default function GameSlider({ sliderData }){
  return (
    <div className={styles.gameSliderContainer}>
      <div className={styles.gamesMore}>
        <FontAwesomeIcon icon={faRocket} style={{ width: '15px', height: '15px' }}/>

        <h2>Новинки</h2>
      </div>

      <div className={styles.swiperContainerGame}>
        <div className={styles.customPrev}>
          <div className={styles.customArrowContainer}>
            <FontAwesomeIcon icon={faChevronLeft} style={{ width: '7.5px', height: '12px' }}/>
          </div>
        </div>
        <div className={styles.customNext}>
          <div className={styles.customArrowContainer}>
            <FontAwesomeIcon icon={faChevronRight} style={{ width: '7.5px', height: '12px' }} />
          </div>
        </div>

        <Swiper
          modules={[Navigation, Autoplay]}
          slidesPerView="auto"
          spaceBetween={13}
          navigation={{
            prevEl: `.${styles.customPrev}`,
            nextEl: `.${styles.customNext}`,
          }}
          autoplay={{ delay: 3000 }}
          speed={500}
        >
          {sliderData.map((slide, index) => (
            <SwiperSlide key={slide.id}>
              <Link href={slide.url}>
                <div className={styles.gameSlideItem}>
                  <Image
                      width={110}
                      height={110}
                      src={slide.image}
                      alt={`Обложка ${slide.name}`}
                      quality={75}
                      style={{ objectFit: "cover", borderRadius: "10px" }} 
                    />
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
